import React, { useState } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import {
    LocalHospital as InjectionIcon,
    MedicalServices as StethoscopeIcon,
    Description as PrescriptionIcon,
    Science as MedicalTestIcon,
    HealthAndSafety as HealthInsuranceIcon,
    MedicalServices as FirstAidKitIcon,
    Home as HomeCareIcon,
    Person as DoctorIcon,
    SmokingRooms as SmokingIcon,
    VideoCall as TelehealthIcon,
    Work as BriefcaseIcon,
    LocalShipping as RoadIcon,
    Commute as LicenseIcon,
    FitnessCenter as ScaleIcon,
} from '@mui/icons-material';
import ServiceCard from './ServiceCard';

function ServicesOffered() {
    const [open, setOpen] = useState({
        immunization: false,
        chm: false,
        mmc: false,
        std: false,
        mac: false,
        preemployment: false,
        workerscomp: false,
        dotphysical: false,
        cdlcert: false,
        weightmgmt: false,
        telehealth: false,
        occupational: false,
    });

    const handleOpen = (service) => {
        setOpen({ ...open, [service]: true });
    };

    const handleClose = (service) => {
        setOpen({ ...open, [service]: false });
    };

    return (
        <Container>
            <Typography variant="h3" align="center" gutterBottom sx={{ mt: 5, mb: 5 }}>
                SERVICES OFFERED
            </Typography>
            <Grid container spacing={4}>
                {[
                    {
                        icon: <InjectionIcon />,
                        title: 'IMMUNIZATION',
                        description: 'Access to adults and children\'s routine vaccines. For example, FLU vaccine, Varicella, Pneumococcal, Shingles vaccine, etc.',
                        stateKey: 'immunization',
                    },
                    {
                        icon: <StethoscopeIcon />,
                        title: 'CHRONIC HEALTH MANAGEMENT',
                        description: 'Manage a wide variety of chronic medical conditions such as diabetes, hypertension, hypothyroidism, Asthma/COPD, Osteoarthritis, etc.',
                        stateKey: 'chm',
                    },
                    {
                        icon: <PrescriptionIcon />,
                        title: 'MEDICAL MARIJUANA CERTIFICATION',
                        description: 'Eligible candidates can become certified via a simple process. We offer in-depth natural pain management with the use of medical marijuana.',
                        stateKey: 'mmc',
                    },
                    {
                        icon: <MedicalTestIcon />,
                        title: 'STD SCREENING',
                        description: 'We provide screening for Gonorrhea, Chlamydia, Syphilis, and other STDs. Quick, rapid results on common STDs such as HIV.',
                        stateKey: 'std',
                    },
                    {
                        icon: <HealthInsuranceIcon />,
                        title: 'MINOR ACUTE CARE',
                        description: 'Quick access to care for minor issues like sprains, minor lacerations, ear infections, sinusitis, etc. No need to wait in an ER.',
                        stateKey: 'mac',
                    },
                    {
                        icon: <FirstAidKitIcon />,
                        title: 'PREEMPLOYMENT PHYSICAL',
                        description: 'Comprehensive preemployment physical exams tailored for employers\' needs.',
                        stateKey: 'preemployment',
                    },
                    {
                        icon: <BriefcaseIcon />,
                        title: 'WORKERS COMPENSATION EVALUATION',
                        description: 'Specialized evaluation and treatment plans for workplace injuries and compensation claims.',
                        stateKey: 'workerscomp',
                    },
                    {
                        icon: <RoadIcon />,
                        title: 'DEPARTMENT OF TRANSPORTATION PHYSICAL',
                        description: 'Certified physical exams for DOT compliance and requirements.',
                        stateKey: 'dotphysical',
                    },
                    {
                        icon: <LicenseIcon />,
                        title: 'COMMERCIAL DRIVERS LICENSE CERTIFICATION',
                        description: 'Medical evaluations and certifications for commercial driver’s license applications.',
                        stateKey: 'cdlcert',
                    },
                    {
                        icon: <ScaleIcon />,
                        title: 'WEIGHT MANAGEMENT CONSULTATION AND TREATMENT',
                        description: 'Personalized consultation and treatment plans for effective weight management.',
                        stateKey: 'weightmgmt',
                    },
                    {
                        icon: <TelehealthIcon />,
                        title: 'TELEHEALTH',
                        description: 'Quick access to a general practitioner through tele audio and video features for treatment and health management.',
                        stateKey: 'telehealth',
                    },
                    {
                        icon: <DoctorIcon />,
                        title: 'OCCUPATIONAL HEALTH',
                        description: 'Comprehensive workplace health assessments and treatment plans to ensure a safe and productive work environment.',
                        stateKey: 'occupational',
                    },
                ].map((service, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <ServiceCard
                            icon={service.icon}
                            title={service.title}
                            description={service.description}
                            open={open[service.stateKey]}
                            handleOpen={() => handleOpen(service.stateKey)}
                            handleClose={() => handleClose(service.stateKey)}
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>

    );
}

export default ServicesOffered;

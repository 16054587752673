import React from 'react';
import { Card, CardContent, Typography, IconButton, Modal, Box } from '@mui/material';

function ServiceCard({ icon, title, description, open, handleOpen, handleClose }) {
    return (
        <>
            <Card onClick={handleOpen} sx={{ cursor: 'pointer', textAlign: 'center', height: '100%' }}>
                <CardContent>
                    <IconButton
                        sx={{
                            fontSize: 60,
                            color: 'teal', // Sets the icon color to teal
                            display: 'flex',
                            margin: '0 auto',
                        }}
                    >
                        {icon}
                    </IconButton>
                    <Typography variant="h6" component="div" gutterBottom>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
            </Card>

            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        textAlign: 'center',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                        <IconButton sx={{ fontSize: 80, color: 'teal' }}>{icon}</IconButton>
                        <Typography variant="h5" sx={{ mt: 2 }}>
                            {title}
                        </Typography>
                    </Box>
                    <Typography variant="body1">{description}</Typography>
                </Box>
            </Modal>
        </>
    );
}

export default ServiceCard;
